import { authExchange as urqlAuthExchange } from '@urql/exchange-auth';
import { iterPossibleHttpStatusOrCodesFromError } from './_util/http.js';
import { walkError } from './_util/walk-error.js';
import { AuthenticationError } from './AuthenticationError.js';
export const authExchange = (authConfigurator) => {
    const init = typeof authConfigurator === 'function' ? authConfigurator : () => authConfigurator;
    return urqlAuthExchange(init);
};
const AUTH_ERROR_CODES = ['401', 'UNAUTHORIZED', '403', 'FORBIDDEN'];
export const isAuthLikeError = (e) => {
    if (e instanceof AuthenticationError) {
        return true;
    }
    else {
        for (const statusOrCode of iterPossibleHttpStatusOrCodesFromError(e)) {
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            if (statusOrCode && AUTH_ERROR_CODES.includes(String(statusOrCode).toUpperCase())) {
                return true;
            }
        }
    }
    return false;
};
export function* iterAuthLikeErrors(error, maxDepth) {
    for (const e of walkError(error, maxDepth)) {
        if (isAuthLikeError(e)) {
            yield e;
        }
    }
}
export const hasAuthLikeError = (error, maxDepth) => {
    for (const _ of iterAuthLikeErrors(error, maxDepth)) {
        return true;
    }
    return false;
};
