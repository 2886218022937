import { map, pipe } from 'wonka';
const AGGREGATE_ERROR_CODE = 'AGGREGATE_ERROR';
/**
 * The data of `@urql/core`'s {@link CombinedError} as a more standard {@link AggregateError}.
 */
export class CombinedAggregateError extends AggregateError {
    name;
    // [next@14.0.3] apparently error digests are not preserved for errors thrown by promises in `use()`.
    // They're supposed to be, since they are when thrown directly from a server component.
    // (See: next.git/test/e2e/app-dir/app/app/error/server-component/custom-digest)
    digest = AGGREGATE_ERROR_CODE;
    static digest = AGGREGATE_ERROR_CODE;
    graphQLErrors;
    networkError;
    response;
    constructor(e) {
        super([...e.graphQLErrors, ...(e.networkError ? [e.networkError] : [])], e.message, { cause: e.cause });
        this.name = 'CombinedAggregateError';
        this.message = e.message;
        this.graphQLErrors = e.graphQLErrors;
        if (e.networkError) {
            this.networkError = e.networkError;
        }
        if (e.response) {
            this.response = e.response;
        }
        if (e.stack) {
            this.stack = e.stack;
        }
        else {
            Error.captureStackTrace(this, new.target);
        }
    }
}
/**
 *  Exchange that replaces {@link CombinedError} as a more standard {@link AggregateError} in `result.error`.
 */
export const nativeCombinedAggregateErrorExchange = () => ({ forward }) => (operations$) => pipe(operations$, forward, map((result) => {
    if (result.error && !(result.error instanceof CombinedAggregateError)) {
        result.error = new CombinedAggregateError(result.error);
    }
    return result;
}));
